<template>
  <div class="pb-1">
    <h3>
      {{ $t("brand") }}
    </h3>

    <Filters
      :search="true"
      :category="true"
      :department="true"
      :categoryData="categoryData"
      :departmentData="departmentData"
      @selectCategory="onSelectCategory"
      @selectDepartment="onSelectDepartment"
      @search="onSearch"
      @addModal="showModal"
    />

    <b-card>
      <b-table
        :items="brandData"
        :fields="fields"
        :busy="isBusy"
        responsive="sm"
        hover
        striped
        show-empty
      >
        <template #empty>
          <h5 class="text-center py-2">{{ $t("no_data") }}</h5>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="mx-2">
              <span v-if="$i18n.locale == 'uz'"> Yuklanmoqda... </span>
              <span v-else-if="$i18n.locale == 'ru'"> Загрузка... </span>
            </strong>
          </div>
        </template>

        <template #cell(image)="row">
          <div class="text-center">
            <img
              :src="row.item.image_adress"
              v-if="row.item.image"
              class="img-thumbnail"
              style="height: 100px"
            />

            <!-- placeholder image -->
            <div
              v-else
              class="no_image"
            >
              {{ $t("no_image") }}
            </div>
          </div>
        </template>

        <template #cell(name)="data">
          <span>
            {{ data.item.name }}
          </span>
        </template>

        <template #cell(actions)="row">
          <div class="text-center d-flex justify-content-center">
            <b-button
              variant="primary"
              size="sm"
              class="mr-1"
              @click="showModal(row.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              variant="danger"
              size="sm"
              @click="showDeleteModal(row.item)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>

    <Pagination
      :users_count="GET_BRANDS.data ? GET_BRANDS.data.pagination[0].totalItems : 0"
      @changePaginationValue="changePaginationValue"
    />

    <BrandAddEditModal
      :isEdit="isEdit"
      :editData="editData"
      :category_id="category_id"
      :department_id="department_id"
      :categoryOptions="GET_PRODUCTS.data.categories"
      :departmentOptions="GET_DEPARTMENTS.data ? GET_DEPARTMENTS.data.departments : []"
      @restart="restart"
    />

    <AreYouSureModal @ok="deleteBrand" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Filters from "@/components/Filters.vue";
import Pagination from "@/components/Pagination.vue";
import BrandAddEditModal from "./components/BrandAddEditModal.vue";
import AreYouSureModal from "@/components/AreYouSureModal.vue";
import {
  BTable,
  BCard,
  BButton,
  BSpinner,
  BModal,
  BFormInput,
  BFormSelect,
  BFormGroup,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BCard,
    BButton,
    BSpinner,
    BModal,
    BFormInput,
    BFormSelect,
    BFormGroup,
    Filters,
    Pagination,
    AreYouSureModal,
    BrandAddEditModal,
  },

  data() {
    return {
      isBusy: true,
      editData: {},
      deleteId: null,
      isEdit: false,
      category_id: null,
      department_id: null,
      pagination: {
        total: null,
        perpage: 10,
        to: 10,
        totalItems: null,
      },
      fields:  [
      {
          key: "image",
          label: this.$t("image"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "name",
          label: this.$t("name"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "priority",
          label: this.$t("priority"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "totalProduct",
          label: this.$t("totalProduct"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "actions",
          label: this.$t("actions"),
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("category", ["GET_PRODUCTS"]),
    ...mapGetters("department", ["GET_DEPARTMENTS"]),
    ...mapGetters("brand", ["GET_BRANDS"]),

    categoryData() {
      return this.GET_PRODUCTS.data?.categories;
    },

    departmentData() {
      return this.GET_DEPARTMENTS.data?.departments;
    },

    brandData() {
      return this.GET_BRANDS.data?.brands;
    },
  },

  async mounted() {
    await this.FETCH_CATEGORIES({ limit: 100 }).then(() => {
      this.category_id = this.GET_PRODUCTS.data.categories[0].id;
    });

    await this.FETCH_DEPARTMENTS({
      limit: 5,
      category_id: this.category_id,
    }).then(() => {
      this.department_id = this.GET_DEPARTMENTS.data.departments[0].id;
    });

    await this.FETCH_BRANDS({
      limit: 10,
      category_id: this.category_id,
      department_id: this.department_id,
    });
    console.log(this.fields)
    this.$nextTick(() => {
      this.isBusy = false;
    });
  },

  onSelectCategoryOfModal() {
    this.FETCH_DEPARTMENTS({
      limit: 5,
      category_id: this.category_id,
    }).then(() => {
      this.department_id = this.GET_DEPARTMENTS.data.departments[0].id;
    });
  },

  watch: {
    // if
  },

  methods: {
    ...mapActions("department", ["FETCH_DEPARTMENTS"]),
    ...mapActions("category", ["FETCH_CATEGORIES"]),
    ...mapActions("brand", ["FETCH_BRANDS", "DELETE_BRAND"]),

    async restart() {
      this.isBusy = true;
      await this.FETCH_BRANDS({
        limit: 5,
        department_id: this.department_id,
      });
      this.$nextTick(() => {
        this.isBusy = false;
      });
    },

    async onSelectCategory(id) {
      console.log(id);
      this.category_id = id;
      this.isBusy = true;
      await this.FETCH_DEPARTMENTS({ limit: 100, category_id: id })
        .then(() => {
          this.$nextTick(() => {
            this.isBusy = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });

      this.FETCH_BRANDS({
        limit: 10,
        category_id: this.category_id,
        department_id: this.department_id,
      });
    },

    async onSelectDepartment(id) {
      console.log(id);
      this.isBusy = true;
      await this.FETCH_BRANDS({
        limit: 10,
        department_id: id.id,
      })
        .then(() => {
          this.department_id = id.id;
          this.$nextTick(() => {
            this.isBusy = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // search filter
    onSearch(search) {
      this.FETCH_BRANDS({ search });
    },

    // open modal
    showModal(item) {
      if (item) {
        this.editData = item;
        this.isEdit = true;
      } else {
        this.isEdit = false;
        this.editData = {};
      }
      this.$nextTick(() => {
        this.$bvModal.show("department-add-modal");
      });
    },

    // open delete modal
    showDeleteModal(item) {
      this.deleteId = item.id;
      this.$nextTick(() => {
        this.$bvModal.show("are-you-sure-modal");
      });
    },

    deleteBrand() {
      console.log(this.deleteId);
      this.DELETE_BRAND(this.deleteId).then(() => {
        this.restart();
      });
    },

    // pagination
    changePaginationValue(value) {
      this.isBusy = true;
      this.FETCH_BRANDS({
        page: value.page,
        limit: value.page_size,
        department_id: this.department_id,
      }).then(() => {
        this.$nextTick(() => {
          this.isBusy = false;
        });
      });
    },
  },
};
</script>

<style scoped>
.no_image {
  margin: 0 auto;
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  border: 1px dashed #ddd;
}
</style>

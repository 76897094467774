<template>
  <b-modal
    id="department-add-modal"
    centered
    :title="isEdit ? $t('editing_brand') : $t('adding_brand')"
    :ok-title="isEdit ? $t('update') : $t('append')"
    :cancel-title="this.$t('cancel')"
    @ok="onSubmit"
    @show="onShow"
  >
    <!-- {{category_id}} -->
    <!-- <br> -->
    <!-- <pre>
      {{this.modalData}}
    </pre> -->
    <b-row>
      <b-col cols="6">
        <b-form-group :label="this.$t('naming') + ':'">
          <b-form-input
            v-model="modalData.name"
            type="text"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="this.$t('priority') + ':'">
          <b-form-input
            v-model="modalData.priority"
            type="number"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group>
          <label>{{ $t("category") }}</label>
          <v-select
            v-model="modalData.category"
            :options="categoryOptions"
            label="name"
            :placeholder="$t('category')"
            @input="onSelectCategory"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group>
          <label>{{ $t("department") }}</label>
          <v-select
            v-model="modalData.bolim"
            label="name"
            :placeholder="$t('department')"
            :options="GET_DEPARTMENTS.data.departments"
          />
          <!-- @input="onSelectDepartment" -->
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group class="text-cetner">
          <div
            class="drag_place my-2"
            v-if="!modalData.showImage || modalData.showImage === 'http://185.17.66.176:60402/img/Test image/'"
          >
            {{ this.$t("browse image") }}
          </div>

          <b-img
            v-else
            v-model="modalData.image"
            class="d-block mx-auto w-100 px-4 py-2"
            :src="modalData.showImage"
          />

        </b-form-group>
        <b-form-group>
          <b-form-file
            id="wildcard"
            accept="image/*"
            @input="loadImg"
            v-model="modalData.downImage"
          />
        </b-form-group>
      </b-col>
      <div class="d-flex justify-content-start mt-1"></div>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import {
  BModal,
  BFormInput,
  BFormFile,
  BFormGroup,
  BRow,
  BCol,
  BImg,
} from "bootstrap-vue";

export default {
  name: "BrandAddEditModal",
  components: {
    BModal,
    BFormInput,
    BFormFile,
    BFormGroup,
    BRow,
    BCol,
    BImg,
    vSelect,
  },
  data() {
    return {
      modalData: {
        name: "",
        priority: "",
        category: "",
        bolim: "",
        image: "",
        showImage: "",
        downImage: "",
      },
    };
  },

  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
    categoryOptions: {
      type: Array,
      default: () => [],
    },
    category_id: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapGetters("department", ["GET_DEPARTMENTS"]),
  },

  methods: {
    ...mapActions("department", ["FETCH_DEPARTMENTS", "ADD_DEPARTMENT"]),
    ...mapActions("brand", ["ADD_BRAND", "UPDATE_BRAND"]),

    onSelectCategory() {
      this.FETCH_DEPARTMENTS({
        category_id: this.modalData.category.id,
      });
    },

    onSubmit() {
      const formData = new FormData();
      formData.append("name", this.modalData.name);
      formData.append("priority", this.modalData.priority);
      formData.append("department_id", this.modalData.bolim.id);
      if (typeof this.modalData.downImage === "object") {
        formData.append("image", this.modalData.downImage);
      }

      if (this.isEdit) {
        formData.append("id", this.modalData.id);
        this.UPDATE_BRAND(formData).then(() => {
            this.$bvToast.toast(this.$t("toast.success"), {
              title: this.$t("success").toUpperCase(),
              variant: "success",
              solid: true,
            });
            this.$emit("restart");
          })
          .catch(() => {
            this.$bvToast.toast(this.$t("toast.error"), {
              title: this.$t("error").toUpperCase(),
              variant: "danger",
              solid: true,
            });
          });;
      } else {
        this.ADD_BRAND(formData).then(() => {
            this.$bvToast.toast(this.$t("toast.success"), {
              title: this.$t("success").toUpperCase(),
              variant: "success",
              solid: true,
            });
            this.$emit("restart");
          })
          .catch(() => {
            this.$bvToast.toast(this.$t("toast.error"), {
              title: this.$t("error").toUpperCase(),
              variant: "danger",
              solid: true,
            });
          });;
      }

      this.$nextTick(() => {
        this.$emit("restart");
      });
    },

    onShow() {
      if (this.isEdit) {
        this.modalData = {
          ...this.editData,
          showImage: this.editData.image_adress,
          category: this.categoryOptions.find(
            (item) => item.id === this.editData.category_id
          ),
        };
      } else {
        this.modalData = {
          name: "",
          priority: "",
          category: "",
          bolim: "",
          image: "",
          showImage: "",
          downImage: "",
        };
      }
    },

    loadImg() {
      this.modalData.image = this.modalData.downImage;
      this.modalData.showImage = URL.createObjectURL(this.modalData.downImage);
    },
  },
};
</script>

<style>
.drag_place {
  width: 100%;
  height: 140px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  line-height: 140px;
  font-size: 20px;
  color: #ccc;
  cursor: pointer;
}
</style>
